/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { flexRender } from '@tanstack/react-table';
import { ReactTableDevtools } from '@tanstack/react-table-devtools';
import React from 'react';
import { useAppViewPort } from 'webComponent/hooks';

import { EmptyMessage } from 'components/molecules/EmptyMessage';
import { ErrorMessage } from 'components/molecules/ErrorMessage';
import { FetchMessage } from 'components/molecules/FetchMessage';
import { StartMessage } from 'components/molecules/StartMessage';
import { isDevelopment } from 'const';

import { useSearchTable } from './useSearchTable';

const testIds = {
    content: 'search-view-content',
    table: 'search-view-table',
    tableHeader: 'search-view-table-header',
    tableRowOdd: 'search-view-table-row-odd',
    tableRowEven: 'search-view-table-row-even',
    fetchMessage: 'search-view-fetch-message',
    emptyMessage: 'search-view-empty-message',
    errorMessage: 'search-view-error-message',
    startMessage: 'search-view-start-message',
} as const;

export { testIds as SearchViewTestIds };
const DEBUG = false;
export const SearchView = () => {
    const { appTop, appHeight } = useAppViewPort();
    const { data, table, queryResult, paginationView } = useSearchTable();
    const messageStyle = { height: `calc(100vh - 58px - ${appTop}px)` };
    React.useEffect(() => {
        const tableRowModel = table.getRowModel();
        DEBUG && console.debug('SearchView-UE: tableRowModel', tableRowModel);
    }, [table]);
    if (queryResult.isFetching) {
        return <FetchMessage data-testid={testIds.fetchMessage} style={messageStyle} />;
    }

    if (queryResult.isError) {
        return <ErrorMessage data-testid={testIds.errorMessage} style={messageStyle} />;
    }

    if (queryResult.fetchStatus === 'idle' && queryResult.isFetched && data?.length === 0) {
        return <EmptyMessage data-testid={testIds.emptyMessage} style={messageStyle} />;
    }

    if (queryResult.fetchStatus === 'idle' && data.length === 0) {
        return <StartMessage data-testid={testIds.startMessage} style={messageStyle} />;
    }
    const tableRowModel = table.getRowModel();
    DEBUG && console.debug('SearchView-render: tableRowModel', tableRowModel);
    return (
        <>
            <div
                data-testid={testIds.content}
                className='bg-background-inverted'
                style={{ height: appHeight - 58 }}
            >
                <table
                    data-testid={testIds.table}
                    className='w-full border-table-border text-notation text-text-default'
                >
                    <thead
                        data-testid={testIds.tableHeader}
                        className='sticky top-0 table w-[calc(100%-10px)] bg-table-header'
                    >
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <th
                                            data-testid={header.id}
                                            key={header.id}
                                            style={{
                                                width: header.column.columnDef.size,
                                                minWidth: header.column.columnDef.minSize,
                                                maxWidth: header.column.columnDef.maxSize,
                                            }}
                                            className={
                                                'border-l border-table-border px-2 py-1 text-left first:rounded-tl-3 first:border-none last:rounded-tr-3'
                                            }
                                        >
                                            {header.isPlaceholder ? null : (
                                                <>
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext(),
                                                    )}
                                                </>
                                            )}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>

                    <tbody
                        className={`custom-scrollbar block w-full overflow-y-scroll`}
                        style={{ height: appHeight - 130 }}
                    >
                        {tableRowModel.rows.map((row, index) => (
                            <React.Fragment key={row.id}>
                                <tr
                                    data-testid={
                                        index % 2 ? testIds.tableRowEven : testIds.tableRowOdd
                                    }
                                    className={`table w-full border-b border-table-border hover:bg-table-cell_background_hover ${
                                        index % 2
                                            ? 'bg-table-cell_background2'
                                            : 'bg-table-cell_background1'
                                    }`}
                                >
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <td
                                                key={cell.id}
                                                style={{
                                                    width: cell.column.columnDef.size,
                                                    minWidth: cell.column.columnDef.minSize,
                                                    maxWidth: cell.column.columnDef.maxSize,
                                                }}
                                            >
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext(),
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
                {paginationView}
            </div>
            {
                /* isDevelopment necessary for tree-shaking */
                isDevelopment && (
                    <div className='fixed bottom-0 z-[99999] w-96'>
                        <ReactTableDevtools table={table} />
                    </div>
                )
            }
        </>
    );
};
