/******************************************************************************
 Unpublished work. Copyright 2024 Siemens

 This material contains trade secrets or otherwise confidential information
 owned by Siemens Industry Software Inc. or its affiliates (collectively,
 "SISW"), or its licensors. Access to and use of this information is strictly
 limited as set forth in the Customer's applicable agreements with SISW.
******************************************************************************/

const DEBUG = false;

export class FIFOQueue<T> {
    private readonly queue: T[] = [];
    private readonly capacity: number;

    constructor(capacity: number) {
        this.capacity = capacity;
    }

    // Add an item to the end of the queue
    enqueue(item: T): void {
        DEBUG &&
            console.debug('FIFOQueue:enqueue - state before', {
                capacity: this.getCapacity(),
                size: this.getSize(),
                currentItem: item,
                queuContent: this.debug(),
            });
        if (this.queue.length >= this.capacity) {
            this.queue.shift(); // Remove the oldest entry if more than capacity
        }
        this.queue.push(item);
    }

    // Remove an item from the front of the queue
    dequeue(): T | undefined {
        const item = this.queue.shift();
        DEBUG &&
            console.debug('FIFOQueue:dequeue - state after', {
                capacity: this.getCapacity(),
                size: this.getSize(),
                currentItem: item,
                queuContent: this.debug(),
            });
        return item;
    }

    // Get the size of the queue
    getSize(): number {
        return this.queue.length;
    }

    // Peek the last item without removing it
    peekLast(): T | undefined {
        return this.queue[this.queue.length - 1];
    }

    // Peek the first item without removing it
    peekFirst(): T | undefined {
        return this.queue[0];
    }

    // Check if the queue is empty
    isEmpty(): boolean {
        return this.queue.length === 0;
    }

    isFull(): boolean {
        return this.queue.length >= this.capacity;
    }

    clear(): void {
        this.queue.length = 0;
    }

    getCapacity(): number {
        return this.capacity;
    }

    // Print the queue elements
    debug(): string {
        return JSON.stringify(this.queue);
    }
}
