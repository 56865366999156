/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import { useDownloadKeyWorker } from '.';
import React from 'react';

import { FIFOQueue } from 'types';
import type { IKeyPoolEntry } from 'types/Download';

const DEBUG = false;
const poolSize = 5;
export type poolType = FIFOQueue<IKeyPoolEntry>;

export interface IDownloadContext {
    debugKeysPool: () => string | undefined;
    chargeKeysPool: () => void;
    dischargeKeysPool: () => string | undefined;
    peekFirstKeyFromPool: () => string | undefined;
    mutateCounter: number;
    enqueueCounter: number;
    dequeueCounter: number;
}

const initializeDownloadContext = (): IDownloadContext => {
    return {
        debugKeysPool: () => {
            return undefined;
        },
        chargeKeysPool: () => {},
        dischargeKeysPool: () => {
            return undefined;
        },
        peekFirstKeyFromPool: () => {
            return undefined;
        },
        mutateCounter: 0,
        enqueueCounter: 0,
        dequeueCounter: 0,
    };
};

const DownloadContext = React.createContext<IDownloadContext>(initializeDownloadContext());

export const DownloadContextProvider = ({ children }: React.PropsWithChildren) => {
    const [keysPool, setKeysPool] = React.useState<poolType>(
        new FIFOQueue<IKeyPoolEntry>(poolSize),
    );
    const { chargeKeysPool, dischargeKeysPool, mutateCounter, enqueueCounter, dequeueCounter } =
        useDownloadKeyWorker({
            keysPool,
            setKeysPool,
        });

    DEBUG &&
        console.debug('DownloadContextProvider: state', {
            mutateCounter,
            enqueueCounter,
            dequeueCounter,
            poolSize: keysPool.getSize(),
            poolContent: keysPool.debug(),
        });

    const debugKeysPool = () => {
        return keysPool.debug();
    };

    const peekFirstKeyFromPool = () => {
        return keysPool.peekFirst()?.key;
    };

    const downloadContext: IDownloadContext = React.useMemo(() => {
        return {
            debugKeysPool,
            chargeKeysPool,
            dischargeKeysPool,
            peekFirstKeyFromPool,
            mutateCounter,
            enqueueCounter,
            dequeueCounter,
        };
    }, [chargeKeysPool, dischargeKeysPool, dequeueCounter, enqueueCounter, mutateCounter]);

    return <DownloadContext.Provider value={downloadContext}>{children}</DownloadContext.Provider>;
};

export const useDownloadContext = () => {
    return React.useContext(DownloadContext);
};
