/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */

// Development
export const isDevelopment = import.meta.env.MODE === 'development';
export const isMockingActive = import.meta.env.VITE_MOCKS === 'true';

// Version
export const semanticVersion = (import.meta.env.SEMANTIC_VERSION as string) || '';
export const commitShortSha = (import.meta.env.VITE_COMMIT_SHA as string) || '';
export const fullVersion = `${semanticVersion}-${commitShortSha}`;

// Urls
export const frontendRoot = (import.meta.env.VITE_FRONTEND_ROOT as string) || '';
export const apiRegionMap: Record<string, string> = JSON.parse(
    import.meta.env.VITE_API_REGION_MAP || '{}',
);
export const pQPortalRegionMap: Record<string, string> = JSON.parse(
    import.meta.env.VITE_PQPORTAL_REGION_MAP || '{}',
);

// Environment
export const environment = (import.meta.env.VITE_ENVIRONMENT as string) || '';

// Analytics
export const sanProductKey = (import.meta.env.VITE_SAN_PRODUCT_KEY as string) || '';
export const sanInternalUsage = (import.meta.env.VITE_SAN_INTERNAL_USAGE as string) === 'true';
