/******************************************************************************
 Unpublished work. Copyright 2024 Siemens

 This material contains trade secrets or otherwise confidential information
 owned by Siemens Industry Software Inc. or its affiliates (collectively,
 "SISW"), or its licensors. Access to and use of this information is strictly
 limited as set forth in the Customer's applicable agreements with SISW.
 ******************************************************************************/
const isOlderThanXMinutes = (current: Date, dateValue: Date, spreadInMinutes: number) => {
    if (current.getTime() - dateValue.getTime() > spreadInMinutes * 60 * 1000) {
        return true;
    }
    return false;
};

const isOlderThanXMinutesSinceNow = (dateValue: Date, spreadInMinutes: number) => {
    const now = new Date();
    return isOlderThanXMinutes(now, dateValue, spreadInMinutes);
};

export const dateUtil = {
    isOlderThanXMinutes,
    isOlderThanXMinutesSinceNow,
};
