/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import type React from 'react';

import { DragIcon24 } from 'assets';
import type { IPluginData } from 'types/PluginData';

import { useDragButton } from './useDragButton';

export interface IDragButtonProps {
    data: IPluginData;
}

const DEBUG = false;

const testIds = {
    button: 'drag-button',
} as const;

export { testIds as DragButtonTestIds };

export const DragButton = ({
    data,
    ...props
}: IDragButtonProps & Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>) => {
    const { handleDragStart } = useDragButton(data);

    return (
        <div
            data-testid={testIds.button}
            {...props}
            onMouseDown={() => {
                DEBUG && console.debug('DragButton:onMouseDown', { data });
            }}
            onDragStart={(event) => {
                DEBUG && console.debug('DragButton:onDragStart', { data });
                handleDragStart(event);
            }}
            onDragEnd={() => {
                DEBUG && console.debug('DragButton:onDragEnd', { data });
            }}
            draggable={true}
        >
            <DragIcon24 className='cursor-pointer' />
        </div>
    );
};
