/*
 * Unpublished work. Copyright 2024 Siemens
 *
 * This material contains trade secrets or otherwise confidential information
 * owned by Siemens Industry Software Inc. or its affiliates (collectively,
 * "SISW"), or its licensors. Access to and use of this information is strictly
 * limited as set forth in the Customer's applicable agreements with SISW.
 */
import React, { useCallback } from 'react';
import { dateUtil } from 'utils';

import { useGetDownloadKeyMutation } from 'api';

import type { poolType } from './DownloadContextProvider';

const DEBUG = false;

export interface IDownloadKeyWorker {
    keysPool: poolType;
    setKeysPool: React.Dispatch<React.SetStateAction<poolType>>;
}

export const useDownloadKeyWorker = (props: IDownloadKeyWorker) => {
    DEBUG && console.debug('useDownloadKeyWorker-begin');
    const downloadKeyMutation = useGetDownloadKeyMutation();
    const workerInterval = 1 * 1000; // 1 second
    const minutesBetweenKeyRefresh = 4;
    const [mutateCounter, setMutateCounter] = React.useState(0);
    const [enqueueCounter, setEnqueueCounter] = React.useState(0);
    const [dequeueCounter, setDequeCounter] = React.useState(0);

    const handleDownloadKeyMutation = () => {
        DEBUG &&
            console.debug('useDownloadKeyWorker:downloadKeyMutation.state - begin', {
                status: downloadKeyMutation.status,
                context: downloadKeyMutation.context,
            });
        if (downloadKeyMutation.isSuccess) {
            DEBUG && console.debug('useDownloadKeyWorker:downloadKeyMutation.isSuccess - begin');
            const downloadKey = downloadKeyMutation.data.downloadKey;
            const lastKey = props.keysPool.peekLast()?.key;
            if (typeof downloadKey === 'string') {
                DEBUG &&
                    console.debug('useDownloadKeyWorker:downloadKeyMutation.isSuccess - key data', {
                        downloadKey,
                        lastKey,
                        firstKey: props.keysPool.peekFirst()?.key,
                    });
                if (lastKey !== downloadKey) {
                    props.keysPool.enqueue({ date: new Date(), key: downloadKey });
                    setEnqueueCounter((prevCount) => prevCount + 1);
                }
            }
            DEBUG && console.debug('useDownloadKeyWorker:downloadKeyMutation.isSuccess - end');
        } else if (downloadKeyMutation.isError) {
            DEBUG &&
                console.error('useDownloadKeyWorker:downloadKeyMutation.isError', {
                    error: downloadKeyMutation.error,
                    failureReason: downloadKeyMutation.failureReason,
                });
        }
    };

    const chargeKeysPool = useCallback(() => {
        DEBUG &&
            console.debug('useDownloadKeyWorker:chargeKeysPool', {
                status: downloadKeyMutation.status,
            });
        if (downloadKeyMutation.status === 'loading' || props.keysPool.isFull()) {
            return;
        }
        downloadKeyMutation.mutate();
        setMutateCounter((prevCount) => prevCount + 1);
    }, [downloadKeyMutation, props.keysPool]);

    const dischargeKeysPool = useCallback(() => {
        const key = props.keysPool.dequeue()?.key;
        if (key) {
            setDequeCounter((prevCount) => prevCount + 1);
        }
        DEBUG && console.debug('dischargeKeysPool: dequeued key', key);
        return key;
    }, [props.keysPool]);

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            DEBUG && console.debug('useDownloadKeyWorker:polling - begin');

            const firstEntry = props.keysPool.peekFirst();
            const isOlder =
                firstEntry &&
                dateUtil.isOlderThanXMinutesSinceNow(firstEntry.date, minutesBetweenKeyRefresh);
            if (isOlder) {
                DEBUG && console.debug('useDownloadKeyWorker:refreshing deprecated entries ...');
                DEBUG &&
                    console.debug('useDownloadKeyWorker:dischargeKeysPool - state before', {
                        mutateCounter,
                        enqueueCounter,
                        dequeueCounter,
                        poolSize: props.keysPool.getSize(),
                        poolContent: props.keysPool.debug(),
                    });
                dischargeKeysPool();
            }

            if (!props.keysPool.isFull()) {
                DEBUG &&
                    console.debug('useDownloadKeyWorker:chargeKeysPool - state before', {
                        mutateCounter,
                        enqueueCounter,
                        dequeueCounter,
                        poolSize: props.keysPool.getSize(),
                        poolContent: props.keysPool.debug(),
                    });
                chargeKeysPool();
            }

            DEBUG && console.debug('useDownloadKeyWorker:polling - end');
        }, workerInterval);

        return () => clearInterval(intervalId); // Cleanup interval on unmount
    }, [
        chargeKeysPool,
        dischargeKeysPool,
        props.keysPool,
        mutateCounter,
        enqueueCounter,
        dequeueCounter,
    ]);

    handleDownloadKeyMutation();

    DEBUG && console.debug('useDownloadKeyWorker-end');
    return { chargeKeysPool, dischargeKeysPool, mutateCounter, enqueueCounter, dequeueCounter };
};
